import fetch from "../../utils/fetch";

export function Save(params) {
  return fetch({
    url: "/epKnowledge/save",
    method: "POST",
    data: params,
  });
}

export function List(params) {
  return fetch({
    url: "/epKnowledge/page",
    method: "GET",
    params:params
  });
}

export function Del(id) {
  return fetch({
    url: `/epKnowledge/${id}/del`,
    method: "DELETE",
  });
}

export function Detail(id) {
  return fetch({
    url: `/epKnowledge/${id}`,
    method: "GET",
  });
}

export function Update(id,query) {
  return fetch({
    url: `/epKnowledge/${id}/update`,
    method: "PUT",
    data:query
  });
}

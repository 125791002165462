export default {
  methods: {
    prevClick() {
      if (this.query.ps > 1) {
        this.query.pn--;
      }
      this.getList(this.query);
    },
    nextClick() {
      this.query.pn++;
      this.getList(this.query);
    },
    pageCurrent(e) {
      this.query.pn = e;
      this.getList(this.query);
    },
    search() {
      let params = {...this.query};
      let checkDefaultEqualBlank = ['isClose','isSubClose'];
      for (let k in params) {
        if (typeof params[k] === "boolean") params[k] = Number(params[k]);
        if (checkDefaultEqualBlank.includes(k)) params[k] ==0 && (params[k] = null);
      }
      params.pn = 1
      this.query.pn = 1
      this.getList(params);
    },
    reset() {
      let checkDefaultEqual0 = [];
      let checkDefaultEqualBlank = ["inChina"];
      if (this.selectedOptions) this.selectedOptions = [];
      this.time = "";
      this.workT = "";
      this.returnT = "";
      for (let k in this.query) {
        if (k != "ps" && k != "pn") this.query[k] = null;
        if (checkDefaultEqual0.includes(k)) this.query[k] = "0";
        if (checkDefaultEqualBlank.includes(k)) this.query[k] = "";
        if(this.query[k]=='') this.query[k] = null
      }
      
      this.getList(this.query);
    },
    sizeChange(e) {
      console.log(e);
      this.query.ps = e;
      this.reset();
    },
  },
};

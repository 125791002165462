<template>
  <section>
    <div style="margin-bottom: 20px">
      <el-button
        type="primary"
        size="small"
        @click="$router.push('/epidemicKnowledgeEdit')"
        >新增</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <!-- <el-table-column type="index" label="序号" width="70px">
      </el-table-column> -->
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="remark" label="描述"> </el-table-column>
      <el-table-column prop="remark" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.enable" effect="plain">启用 </el-tag>
          <el-tag v-else effect="plain" type="danger"> 禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="上传时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="goDetail(scope.row.id)"
            >修改</el-button
          >
          <el-button type="text" @click="setStatus(scope.row)"
            >{{ scope.row.enable ? "禁用" : "启用" }}
          </el-button>
          <el-button type="text" style="color: red" @click="del(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total,sizes,prev, pager, next"
      style="text-align: right; margin-top: 20px"
      @size-change="sizeChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      @current-change="pageCurrent"
      :page-size="query.ps"
      :total="total"
    >
    </el-pagination>
  </section>
</template>

<script>
import { List, Del, Update } from "@/api/infomation/knowledge";
import page from "@/mixins/page";
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      query: {
        ps: 20,
        pn:1
      },
    };
  },
  mixins: [page],
  created() {
    this.getList(this.query);
  },
  methods: {
    async getList(query) {
      let res = await List(query);
      this.tableData = res.data.data.list;
      this.total = res.data.data.total;
    },
    del(id) {
      this.$confirm("确认删除？", "提示").then(async () => {
        let res = await Del(id);
        this.$message.success("删除成功");
        this.getList();
      });
    },
    goDetail(id) {
      this.$router.push(`/epidemicKnowledgeEdit/${id}`);
    },
    setStatus(item) {
      Update(item.id, { enable: item.enable ? 0 : 1 }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
      });
    },
  },
};
</script>

<style>
</style>